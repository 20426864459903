import React, { CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';
import useGlobalVariables from '../hooks/useGlobalVariables';
import useTeams from '../hooks/useTeams';
import { raw } from '../utils/textUtils';
import Chevron from './icons/Chevron';
import AdSpace from './ui/AdSpace';
import LinedTitle from './ui/LinedTitle';
import NewsCard from './ui/NewsCard';

export default function GlobalSidebar() {
	const { HomePage, isLoaded } = useGlobalVariables();

	const {
		hasFavoriteTeam,
		favoriteTeam: team,
	} = useTeams();

	const {
		FavoriteTeam,
		NewsFeed,
	} = HomePage || {};

	return isLoaded && (
		<div className="sidebar">
			{!hasFavoriteTeam && <NavLink className="favorite-team" to={FavoriteTeam.Link}>
				<div className="content">
					<div className="title" {...raw(FavoriteTeam.Title)} />
					<div className="text" {...raw(FavoriteTeam.Text)} />
				</div>
				<div className="logo">
					<img src={FavoriteTeam.Logo} alt="" />
				</div>
				<Chevron />
			</NavLink>}
			{team && hasFavoriteTeam && <NavLink className="favorite-team" style={{ '--team-color': team.data.MainColor } as CSSProperties} to={FavoriteTeam.LinkCompleted}>
				<div className="content">
					<div className="title" {...raw(FavoriteTeam.TitleCompleted)} />
					<div className="text" {...raw(FavoriteTeam.TextCompleted)} />
				</div>
				<div className="logo">
					<img src={team.data.Logo as string} alt="" />
				</div>
				<Chevron />
			</NavLink>}

			<AdSpace id="homepage-sidebar" />

			<div className="news-feed">
				<LinedTitle text={'News Feed <strong>March 10</strong>'} />
				<div className="feed">
					{NewsFeed.map((news) => <NewsCard key={JSON.stringify(news)} {...news} />)}
				</div>
			</div>
		</div>
	);
}