/* eslint-disable no-console */
import { faComments, faSmileWink, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo, useEffect, useRef, useState } from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useSelector } from 'react-redux';
import { IRootState, useAppDispatch } from '../../redux/config/store';
import { addChatMessage, ChatMessage, sendChatMessage } from '../../redux/chat';
import { guid } from '../../utils/guid';
import Message from './Message';
import ChatTooltip from './ChatTooltip';
import usePlayer from '../../hooks/usePlayer';

type ChatBoxProps = {
	roomId?: string;
};

function ChatBox({ roomId = 'general' }: ChatBoxProps) {
	const isOpened = true; //[isOpened, setIsOpened] = useState(true);
	const [isPickerVisible, setIsPickerVisible] = useState(false);

	const [highlightedMessage, setHighlightedMessage] = useState<{ message:ChatMessage, y:number }>(null);

	const { DisplayName, playerId } = usePlayer();

	const [chatMessage, setChatMessage] = useState('');
	const dispatch = useAppDispatch();

	const msgEnd = useRef<HTMLDivElement>(null);

	function scrollToBottom() {
		msgEnd.current.scrollIntoView({ behavior: 'smooth' });
	}

	function onMessageHover({ message, y }: { message: ChatMessage, y: number }) {
		setHighlightedMessage({ message, y });
	}

	function onMessageHoverOut() {
		setHighlightedMessage(null);
	}
	
	const messages = useSelector((state:IRootState) => state.chat.messages[roomId] || []);
	
	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	useEffect(() => {
		console.log('Joined Chat Room:', roomId);
		
		return () => {
			console.log('Leaved Chat Room:', roomId);
		};
	}, []);

	function onSubmit(e:React.KeyboardEvent<HTMLInputElement>) {
		if (e.key === 'Enter') {
			e.preventDefault();
			if (!chatMessage) return;
			const g = guid();
			dispatch(sendChatMessage({ text: chatMessage, room_id: roomId, guid: g }));
			dispatch(addChatMessage({
				TimeStamp: Date.now(),
				DisplayName,
				Payload: {
					Message: chatMessage,
				},
				Guid: g,
				Room: roomId,
				Type: 'user',
				PlayFabId: playerId,
			}));
			setChatMessage('');
			setIsPickerVisible(false);
		}
	}

	return (
		<div className={`chat-box ${isOpened ? 'is-open' : ''}`}>
			<div className="toggle">
				<FontAwesomeIcon icon={faComments} />
			</div>

			<div className="mask">
				<ChatTooltip message={highlightedMessage?.message} y={highlightedMessage?.y} />
				<div className="chat-box-inner">
					<div className="header">
						Chat room
					</div>
					<div className="messages-list">
						{messages.map((message, index) => (
							<Message
								message={message}
								key={index}
								onHover={onMessageHover}
								onHoverOut={onMessageHoverOut}
							/>
						))}
						<div ref={msgEnd} />
					</div>
					<div className="chat-input">
						<div className="emoji-picker">
							{isPickerVisible && <div className="picker">
								<Picker data={data} onEmojiSelect={(e) => setChatMessage(m => m + e.native)} />
							</div>}
							<div className="btn" onClick={() => setIsPickerVisible(v => !v)}>
								{isPickerVisible ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faSmileWink} />}
							</div>
						</div>
						<input type="text" onChange={(v) => setChatMessage(v.target.value)} onKeyDown={onSubmit} placeholder="Type your message here..." value={chatMessage} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default memo(ChatBox);
