import React, { memo } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { ROUTES } from './Constants';
import '../css/index.scss';
import Home from './components/routes/Home';
import Layout from './components/Layout';
import usePlayFab from './hooks/usePlayFab';
import Login from './components/routes/Login';
import useRealtime from './hooks/useRealtime';
import Media from './components/routes/Media';
import Match from './components/routes/Match';
import Profile from './components/routes/Profile';
import Store from './components/routes/Store';
import useInventoryExpiration from './hooks/useInventoryExpiration';
import useHeartbeat from './hooks/useHeartbeat';
import useRouteEvents from './hooks/useRouteEvents';
import Notifications from './components/Notifications';
import Debug from './components/Debug';
// import Challenges from './components/routes/Challenges';
import HighlightMatch from './components/routes/HighlightMatch';
// import Challenge from './components/routes/Challenge';
import RFIDLogin from './components/RFIDLogin';
import RewardTracks from './components/routes/RewardTracks';
import MatchPrediction from './components/routes/MatchPrediction';
import PredictionResolved from './components/predictions/PredictionResolved';
import PageBackground from './components/ui/PageBackground';
import useXRStore from './hooks/useXRStore';

function App() {
	const {
		playerId,
	} = usePlayFab();

	useRealtime();
	useInventoryExpiration();
	useHeartbeat();
	useRouteEvents();

	useXRStore();

	const isLogin = useMatch(ROUTES.ROOT + ROUTES.LOGIN);

	return (
		<>
			{!isLogin && !playerId && <Navigate to={ROUTES.LOGIN} replace={true} />}
			<Debug />
			<RFIDLogin />
			<Routes>
				<Route path={ROUTES.ROOT} element={<Layout />}>
					<Route index element={<Home />} />
					<Route path={ROUTES.LOGIN} element={<Login />} />
					<Route path={ROUTES.MEDIA} element={<Media />} />
					<Route path={ROUTES.MATCH} element={<Match />} />
					<Route path={ROUTES.HIGHLIGHT_MATCH} element={<HighlightMatch />} />
					<Route path={ROUTES.PROFILE} element={<Profile />} />
					<Route path={ROUTES.STORE} element={<Store />} />
					<Route path={ROUTES.REWARD_TRACKS} element={<RewardTracks />} />
					{/*<Route path={ROUTES.CHALLENGES} element={<Challenges />} />/** */}
					{/*<Route path={ROUTES.CHALLENGE} element={<Challenge />} />/** */}
					<Route path={ROUTES.MATCH_PREDICTION} element={<MatchPrediction />} />
					<Route path={ROUTES.MATCH_PREDICTION_RESOLVE} element={<PredictionResolved />} />
				</Route>
			</Routes>
			<Notifications />
			<PageBackground />
		</>
	);
}

export default memo(App);
