/* eslint-disable space-before-function-paren */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { CSSProperties, memo, useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimer } from '@fortawesome/pro-regular-svg-icons';

import useBadges from '../../hooks/useBadges';
import useGlobalVariables from '../../hooks/useGlobalVariables';
import useStats from '../../hooks/useStats';
import useTeams from '../../hooks/useTeams';
import useXP from '../../hooks/useXP';
import numberWithCommas from '../../utils/numberWithCommas';
import Chevron from '../icons/Chevron';
import Avatar from '../ui/Avatar';
import Badge from '../ui/Badge';
import LevelBadge from '../ui/LevelBadge';
import LinedTitle, { LinedTitleTypes } from '../ui/LinedTitle';
import { useLogout } from '../../hooks/useLogout';
import Missions from '../Missions';
import usePlayer from '../../hooks/usePlayer';
import useBackground from '../../hooks/useBackground';
import useRewardTracks from '../reward-track/hooks/useRewardTracks';
import RewardTrack from '../reward-track/RewardTrack';
import usePolls from '../../hooks/usePolls';
import LoadingSpinner from '../icons/LoadingSpinner';
import Loading from '../ui/Loading';
import CurrencyFloater from '../ui/CurrencyFloater';

function Profile() {
	const {
		DisplayName,
		currencies: { CO },
	} = usePlayer();

	const page = useRef<HTMLDivElement>(null);
	const content = useRef<HTMLDivElement>(null);

	const { Logout: OnClickLogout } = useLogout();

	const { ProfilePage, isLoaded } = useGlobalVariables();

	const {
		BackgroundImage,
	} = ProfilePage || {};

	const {
		teams,
		setFavoriteTeam,
		hasFavoriteTeam,
		favoriteTeam,
	} = useTeams();

	const {
		xp,
		nextLevelXp,
	} = useXP();

	const {
		matches_watched,
		minutes_watched,
		achievements_unlocked,
		viewer_type,
	} = useStats();

	const badges = useBadges();

	const { setBackground } = useBackground();
	useEffect(() => {
		setBackground(favoriteTeam?.data?.BackgroundImage as string || BackgroundImage);
	});

	const tracks = useRewardTracks().filter(t => t.type.includes('team-' + favoriteTeam?.data?.TeamId));
	
	const style = {
		'--background-image': `url(${favoriteTeam?.data?.BackgroundImage})`,
		'--team-main-color': favoriteTeam?.data?.MainColor,
		'--team-secondary-color': favoriteTeam?.data?.SecondaryColor,
	} as CSSProperties;

	const {
		polls,
		answerPoll,
		answers,
	} = usePolls();

	const poll = polls.find(p => p?.poll?.customData?.teamId === favoriteTeam?.data?.TeamId);

	const [isLoading, setIsLoading] = useState(false);

	const answer = answers ? answers?.[poll?.instanceId] : null;

	const onAnswerPoll = useCallback(async (choice:string) => {
		setIsLoading(true);
		await answerPoll(poll, choice);
		setIsLoading(false);
	}, [poll]);
	
	if (!isLoaded) return (
		<Loading isLoading={isLoaded} />
	);

	return (
		<CSSTransition nodeRef={page} key="profile" timeout={1200} in={true} appear>
			<div className="page profile" ref={page}>
				<div className="page-content" style={style}>
					<CSSTransition key={favoriteTeam?.id} nodeRef={content} in={Boolean(favoriteTeam)} timeout={1200} mountOnEnter unmountOnExit appear>
						<div className="content" ref={content}>
							<div className="page-title">
								<h2 className="animated" style={{ '--i': 0 } as CSSProperties}>My Team</h2>
								<h1 className="animated" style={{ '--i': 1 } as CSSProperties}>{favoriteTeam?.playfab?.DisplayName}</h1>
							</div>

							<div className="reward-track-table-container animated" style={{ '--i': 2 } as CSSProperties}>
								<table className="reward-tracks">
									<tbody>
										{tracks.map((track, i) => <RewardTrack key={track.id} trackId={track.id} showTracker={i === 0} />)}
									</tbody>
								</table>
							</div>

							<div className="trivia-ctn">
								<h2 className="animated" style={{ '--i': 3 } as CSSProperties}>Trivia</h2>
								<p className="animated" style={{ '--i': 4 } as CSSProperties}>Test your knowledge to win rewards!</p>
								<CSSTransition in={Boolean(poll)} timeout={1200} mountOnEnter unmountOnExit appear>
									<div className={`trivia ${answer ? 'answered' : ''}`} key={poll?.instanceId}>
										{poll && <>
											<div className="question">{poll.poll.question}</div>
											<div className="ctn">
												<CSSTransition in={!answer && !isLoading} timeout={1200} appear>
													<div className="choices">
														{poll.poll.choices.map(choice => (
															<div className={`choice ${choice?.id === answer?.choice ? 'selected' : ''}`} key={choice?.id} onClick={() => onAnswerPoll(choice?.id)}>
																<div className="text">{choice?.label}</div>
															</div>
														))}
													</div>
												</CSSTransition>
												<CSSTransition in={isLoading} timeout={1200} appear>
													<div className="spinner"><LoadingSpinner /></div>
												</CSSTransition>
												<CSSTransition in={Boolean(answer)} timeout={1200} appear>
													<div className="answer">
														<div className="text">
															{answer?.choice === answer?.correctAnswer ? 'Good answer!' : 'Wrong answer!'}
														</div>
														{answer?.choice !== answer?.correctAnswer && <div className="correct">The correct answer was: {poll?.poll?.choices?.find?.(c => c.id === answer.correctAnswer)?.label}</div>}
													</div>
												</CSSTransition>
											</div>
										</>}
									</div>
								</CSSTransition>
								<CSSTransition in={!Boolean(poll)} timeout={{ appear: 1200, enter: 1200, exit: 0 }} mountOnEnter unmountOnExit appear>
									<div className="trivia no-trivia">Generating Trivia <LoadingSpinner width="32" /></div>
								</CSSTransition>
							</div>
						</div>
					</CSSTransition>
				</div>
				<div className="sidebar">
					<LinedTitle text='Your <strong>profile</strong>' size={LinedTitleTypes.LARGE} caps />
					<div onClick={OnClickLogout} className="logout-button">Log Out</div>
					<div className="player-profile">
						<div className="rounded player-card">
							<div className="content">
								<Avatar />
								<div className="side">
									<div className="player-name">{DisplayName} <LevelBadge /></div>
									<div className="xp"><span className="current">XP {numberWithCommas(xp)}</span> / {numberWithCommas(nextLevelXp)}</div>
									<div className="role">VIP Member</div>
								</div>
							</div>
							<Chevron />
						</div>
						<div className="rounded wallet">
							<CurrencyFloater />
							<div className="amount">{numberWithCommas(CO)}</div>
							<div className="currency">Coins</div>
						</div>
					</div>
					<div className="rounded favorites">
						<LinedTitle text='Favorite <strong>Team</strong>' caps />
						<div className="teams">
							{teams.map(team => (
								<div className={`team ${team.data.IsFavorite ? 'favorite' : ''}`} onClick={() => setFavoriteTeam(team.data.TeamId as number)} key={team.itemId}>
									<img src={team.data.Logo as string} alt={team.playfab.DisplayName} />
								</div>
							))}
						</div>
						{!hasFavoriteTeam && <div className="no-team">Select your favorite team by clicking on one of the team logo.</div>}
					</div>
					<LinedTitle className='missions-title' text='Your <strong>Challenges</strong>' caps />
					<Missions />
					<div className="weekly-mission rounded">
						<div className="title">Challenge Week 14</div>
						<div className="text">Complete all challenge to win a 30 days friends pass and unlock your grand master avatar profile!</div>
						<div className="time-remaining">
							<FontAwesomeIcon icon={faTimer} /> 5D - 12H
						</div>
					</div>
					<LinedTitle className='achievements-title' text='Your <strong>Achievements</strong>' caps />
					<div className="rounded stats-panel">
						<LinedTitle className='stats-title' text='Your <strong>Statistics</strong>' caps />
						<div className="stats-grid">
							<div className="stat">
								<div className="name"><strong>Matches</strong> watched</div>
								<div className="value">{matches_watched}</div>
							</div>
							<div className="stat">
								<div className="name"><strong>Minutes</strong> watched</div>
								<div className="value">{minutes_watched}</div>
							</div>
							<div className="stat">
								<div className="name"><strong>Achievements</strong> unlocked</div>
								<div className="value">{achievements_unlocked}</div>
							</div>
							<div className="stat">
								<div className="name"><strong>Viewer</strong> Type</div>
								<div className="value">{viewer_type}</div>
							</div>
						</div>
					</div>
					<div className="badges">
						{badges.map(badge => (
							<Badge key={badge.itemId} item={badge} />
						))}
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}

export default memo(Profile);