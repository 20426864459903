import React, { useEffect } from 'react';
import { XRAdActivityTypes } from '../../api/enums';
import useAdPlacement from '../../hooks/useAdPlacement';

type AdSpaceProps = {
	id: string;
};

export default function AdSpace({ id = '' }:AdSpaceProps) {
	const {
		type,
		img,
		iframe,
		video,
		reportAdActivity,
	} = useAdPlacement(id);

	useEffect(() => {
		reportAdActivity?.(XRAdActivityTypes.Opened);

		return () => {
			reportAdActivity?.(XRAdActivityTypes.Closed);
		};
	}, []);

	return (
		<div className={`ad-space ${type}`}>
			{img && <img src={img} alt="" />}
			{video && <video src={video} playsInline autoPlay muted loop />}
			{iframe && <iframe src={iframe} title={id} />}
		</div>
	);
}