import React, { memo, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useSwipeable } from 'react-swipeable';

import useGlobalVariables from '../../hooks/useGlobalVariables';
import useXRStore from '../../hooks/useXRStore';
import { raw } from '../../utils/textUtils';
import Button from '../ui/Button';
import Loading from '../ui/Loading';
import useBackground from '../../hooks/useBackground';


function Store() {
	const { isLoaded } = useGlobalVariables();
	const page = useRef<HTMLDivElement>(null);
	const sidebar = useRef<HTMLDivElement>(null);

	const {
		StorePages,
		CurrentSection,
		SetCurrentSlide,
		NextSlide,
		PreviousSlide,
		IsReady,
	} = useXRStore();

	const handlers = useSwipeable({
		onSwipedRight: NextSlide,
		onSwipedLeft: PreviousSlide,
	});
	
	const [sidebarOpened, setSidebarOpened] = useState(false);

	const { setBackground } = useBackground();
	useEffect(() => {
		setBackground(CurrentSection?.BackgroundImage || '');
	});

	
	if (!isLoaded || !IsReady || !CurrentSection) {
		return (
			<div className="page store" ref={page}>
				<Loading isLoading={isLoaded} />
			</div>
		);
	}

	return (
		<CSSTransition nodeRef={page} key="store" timeout={1200} in={true} appear>
			<div className="page store" ref={page}>
				<div className="bgs"></div>
				<div className="page-content">
					<div className="slides" {...handlers}>
						{StorePages.map((slide) => (
							<div className={`slide ${slide === CurrentSection ? 'active' : ''}`} key={slide.Id}>
								<div className="content">
									<div className="title" {...raw(slide.Title)} />
									<div className="text" {...raw(slide.Text)} />
									<Button className="big" label={slide.ButtonLabel} onClick={() => setSidebarOpened(true)} hasArrow />
								</div>
							</div>
						))}

						<div className="dots">
							{StorePages.map((slide, index) => (
								<div className={`dot ${slide === CurrentSection ? 'active' : ''}`} key={slide.Id} onClick={() => SetCurrentSlide(index)} />
							))}
						</div>
					</div>
				</div>

				<CSSTransition nodeRef={sidebar} timeout={1200} in={true} appear key={CurrentSection.Id}>
					<div className={`sidebar ${sidebarOpened ? 'opened' : ''}`} ref={sidebar}>
						<div className="toggle" onClick={() => setSidebarOpened(v => !v)}>
							<svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14.414 12.828"><g fill="none" stroke="#f9f9f9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"><path d="m6 11.414-5-5 5-5"/><path d="m13 11.414-5-5 5-5"/></g></svg>
						</div>
						{CurrentSection.StoreCategories.map((category) => (
							<div className="store-section" key={category.Name}>
								<div className="title">
									<div {...raw(category.Name)} />
									<div className="view-all" onClick={() => setSidebarOpened(true)}>View All</div>
								</div>
								<div className="products">
									{category.Products.map(({ tile, item }) => {
										return (<div className="product" key={tile.id}>
											{item.data.Image && <div className="image">
												<img src={item.data.Image.toString()} alt="" />
											</div>}
											<div className="name" {...raw(item.playfab.DisplayName)} />
											<div className="variations" {...raw(item.data.Variations.toString())} />
											<div className="price">
												{tile.discountPrice !== tile.regularPrice && (
													<div className="sale-price" {...raw(`$${tile.discountPrice}`)} />
												)}
												<div className="regular-price" {...raw(`$${tile.regularPrice}`)} />
											</div>
										</div>);
									})}
								</div>
							</div>
						))}
					</div>
				</CSSTransition>
			</div>
		</CSSTransition>
	);
}

export default memo(Store);
