import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRootState, useAppDispatch, useAppSelector } from '../redux/config/store';
import usePlayer from './usePlayer';
import { fetchPredictionData, fetchPredictions, fetchUnclaimedPredictions } from '../redux/predictions';
import usePlayerData from './usePlayerData';


function usePredictions() {
	const dispatch = useAppDispatch();
	const { isPlayerDataLoaded, playerData } = usePlayerData();
	const { playerId } = usePlayer();

	const forceFetchPrediction = useSelector((state: IRootState) => state.predictions.forceFetchPredictions);
	const predictions = useSelector((state: IRootState) => state.predictions.predictions);
	const predictionData = useSelector((state: IRootState) => state.predictions.predictionData);
	const unclaimedPredictions = useSelector((state: IRootState) => state.predictions.unclaimedPredictions);

	useEffect(() => {
		if (playerId || forceFetchPrediction) {
			dispatch(fetchPredictions());
			dispatch(fetchUnclaimedPredictions());
		}
	}, [playerId, forceFetchPrediction]);

	
	return useMemo(() => {
		return predictions.map((p) => {
			const pData = predictionData[p.instanceId];
			const isAnswered = (isPlayerDataLoaded && playerData?.Challenges?.[p.instanceId]) || null;
			const isUnclaimed = unclaimedPredictions.includes(p.instanceId);

			if (!pData) {
				dispatch(fetchPredictionData({ url: p.jsonUrl }));
			}

			return {
				...p,
				data: predictionData[p.instanceId] || null,
				isAnswered,
				isUnclaimed,
			} as Prediction;
		});
	}, [predictions, predictionData, unclaimedPredictions, isPlayerDataLoaded, playerData]);
}

export default function useChallenges() {
	const predictions = usePredictions();
	return useMemo(() => predictions.filter((p) => p.data?.questions?.length > 1), [predictions]);
}

export function useChallenge(instanceId):Prediction | null {
	const challenges = useChallenges() || [];
	return useMemo(() => challenges.find((c) => c.instanceId === instanceId) || null, [challenges, instanceId]);
}

export function useActivePrediction() {
	const dispatch = useAppDispatch();
	const eventPredictionInstanceId = useAppSelector(state => state.predictions.active_prediction?.instanceId);
	const prediction = useAppSelector(state => state.predictions.predictions.find(p => p.instanceId === eventPredictionInstanceId));
	const predictionData = useAppSelector(state => state.predictions.predictionData);
	const unclaimedPredictions = useSelector((state: IRootState) => state.predictions.unclaimedPredictions);
	const { isPlayerDataLoaded, playerData } = usePlayerData();

	useEffect(() => {
		dispatch(fetchUnclaimedPredictions());
	}, []);

	if (!prediction) return null;

	const pData = predictionData[prediction.instanceId];
	const isAnswered = (isPlayerDataLoaded && playerData?.Challenges?.[prediction.instanceId]) || null;
	const isUnclaimed = unclaimedPredictions.includes(prediction.instanceId);

	if (!pData) {
		//dispatch(fetchPredictionData({ url: prediction.jsonUrl }));
		return null;
	}

	return {
		...prediction,
		data: pData,
		isAnswered,
		isUnclaimed,
	};
}

export function useUnclaimedPrediction() {
	const predictions = usePredictions();
	return predictions.find((p) => p.isUnclaimed);
}


export function usePredictionRewards(prediction:Prediction) {
	if (!prediction || !prediction.rewards) return null;

	const rewards = useMemo(() => {
		let rewardArr = prediction?.rewards?.challenge || [];
	
		if (prediction?.rewards?.questions) {
			Object.values(prediction?.rewards?.questions)?.forEach(({ question, choices }) => {
				if (question && question.length > 0) rewardArr = [...rewardArr, ...question];
	
				if (choices) {
					Object.values(choices).forEach((c) => {
						if (c && c.length > 0) rewardArr = [...rewardArr, ...c];
					});
				}
			});
		}

		return rewardArr;
	}, [prediction]);

	return rewards;
}