import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { /*PREDICTION_MATCH_ID,/** */ PREDICTION_MATCH_ID, ROUTES } from '../../Constants';
import useBackground from '../../hooks/useBackground';
import { useActivePrediction } from '../../hooks/useChallenges';
import useGlobalVariables from '../../hooks/useGlobalVariables';
import usePlayerData from '../../hooks/usePlayerData';
import { useAppDispatch } from '../../redux/config/store';
import { updatePlayerData } from '../../redux/playerData';
import { writePlayerEvent } from '../../redux/playfab';
import { answerPredicitons, instantiatePrediction } from '../../redux/predictions';
import LoadingSpinner from '../icons/LoadingSpinner';
import Button from '../ui/Button';

export enum PredictionStates {
	WAITING = 'WAITING',
	OPENED = 'OPENED',
	ANSWERED = 'ANSWERED',
	RESOLVED = 'RESOLVED',
	CLAIMED = 'CLAIMED',
}

export default function MatchPrediction() {
	const dispatch = useAppDispatch();

	const activePrediction = useActivePrediction();
	const [activeChoice, setActiveChoice] = React.useState<string | null>(null);
	const [isAnswering, setIsAnswering] = React.useState(false);
	const { playerData } = usePlayerData();
	const [isWaitingForPrediction, setIsWaitingForPrediction] = useState(true);
	const [predictionState, setPredictionState] = useState<PredictionStates>(PredictionStates.WAITING);
	const { setBackground } = useBackground();
	const navigate = useNavigate();
	const { MatchPage, isLoaded: isGlobalVariablesLoaded } = useGlobalVariables();


	useEffect(() => {
		setBackground('https://xrdatalakenab2022.blob.core.windows.net/react/uddq8e/assets/homepage_bg.jpg');
		setIsWaitingForPrediction(true);
		setPredictionState(PredictionStates.WAITING);
		dispatch(writePlayerEvent({ name: 'match_started' }));
	}, []);
	
	useEffect(() => {
		if (isGlobalVariablesLoaded) {
			dispatch(instantiatePrediction({ definitionId: MatchPage.FallbackPredictionDefId, matchId: PREDICTION_MATCH_ID }));
		}
	}, [isGlobalVariablesLoaded]);

	useEffect(() => {
		if (activePrediction && !activePrediction.isAnswered && !isAnswering && predictionState === PredictionStates.WAITING) {
			setIsWaitingForPrediction(false);
			setPredictionState(PredictionStates.OPENED);
		}
	}, [activePrediction]);

	const submitAnswer = useCallback(() => {
		setIsAnswering(true);

		const answers = {
			[activePrediction.data.questions[0].id]: activeChoice,
		};
		
		dispatch(answerPredicitons({
			InstanceId: activePrediction.instanceId, 
			Answers: answers,
		})).then(() => {
			setIsAnswering(false);
			dispatch(updatePlayerData({
				Challenges: {
					...playerData.Challenges,
					[activePrediction.instanceId]: answers,
				},
			}));
		});	

		navigate(ROUTES.ROOT + ROUTES.MATCH);
	}, [activePrediction, activeChoice, dispatch]);

	let content = null;
	switch (predictionState) {
		case PredictionStates.OPENED:
			content = (
				<div className="match-prediction">
					<div className="title">New prediction!</div>

					<div className="prediction-content">
						{activePrediction?.data?.questions && activePrediction?.data?.questions.map(question => (
							<div key={question.id}>
								<div className="question">{question.question}</div>
								<div className="choices">
									{question.choices.map(choice => (
										<div key={choice.id} className={`choice ${choice.code === activeChoice && 'active'}`} onClick={() => setActiveChoice(choice.code)}>
											{choice.label}
										</div>
									))}
								</div>
							</div>
						))}
						<Button className="big" onClick={submitAnswer} label="Submit answer" disabled={!activeChoice} />
					</div>
				</div>
			);
			break;
		case PredictionStates.WAITING:
		default:
			content = <LoadingSpinner className="spinner" />;
			break;
	}

	
	return (
		<CSSTransition appear timeout={1200} in>
			<div className="page match-prediction-page">
				<SwitchTransition>
					<CSSTransition appear key={predictionState} timeout={1200} in={isWaitingForPrediction}>	
						{content}
					</CSSTransition>
				</SwitchTransition>
			</div>
		</CSSTransition>

	);
}
