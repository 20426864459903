import { MISSIONS_TYPES } from '../../../Constants';
import useCatalog from '../../../hooks/useCatalog';
import useMissions from '../../../hooks/useMissions';
import { IPlayerStatistics } from '../../../redux/statistics';

type IXRewardTrackObjective = IXRMissionObjectiveParsedData & {
	data: {
		threshold: number;
	}
};

type IXRRewardTrack = IXRMissionItem & {
	data: {
		startTime?: string;
		endTime?: string;
		trackName: string;
		trackType: string;
		statisticName: keyof IPlayerStatistics;
	},
	objectives: IXRewardTrackObjective[];
};

type RewardTrackTier = {
	id: number,
	name: string;
	threshold: number;
	rewards: IXRInventoryItemParsedData[];
	isComplete: boolean;
};

type RewardTrack = {
	id: string;
	tiers: RewardTrackTier[];
	startTime: Date;
	endTime: Date;
	name: string;
	type: string;
	statisticName: keyof IPlayerStatistics;
	isActive: boolean;
};

function useRewardTracks():RewardTrack[] {
	const catalog = useCatalog();
	const tracks = useMissions(MISSIONS_TYPES.REWARD_TRACK) as IXRRewardTrack[];

	return (tracks).map(t => {
		const tiers = (t.objectives).map((o) => {
			return {
				id: o.id,
				name: o.title,
				threshold: Number(o.data.threshold || 0),
				isComplete: o?.PlayerStatus?.IsComplete || false,
				rewards: o.rewards.map(r => {
					switch (r.dataType) {
						case 'item_grant':
							return catalog.items.find(i => i.itemId === r.dataVal);
						case 'currency_grant':
							return catalog.items.find(i => i.itemId === 'i-1679689432850-266');
					}
					return null;
				}),
			};
		});

		tiers.sort((a, b) => a.threshold - b.threshold);

		return {
			id: t.itemId,
			tiers,
			isActive: Boolean(t.PlayerStatus),
			startTime: new Date(t.data.startTime),
			endTime: new Date(t.data.endTime),
			name: t.playfab.DisplayName,
			type: t.data.trackType,
			statisticName: t.data.statisticName,
		};
	});
}

export default useRewardTracks;