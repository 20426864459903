import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { ROUTES } from '../../Constants';
import useBackground from '../../hooks/useBackground';
import { useActivePrediction } from '../../hooks/useChallenges';
import usePlayerData from '../../hooks/usePlayerData';
import { useAppDispatch } from '../../redux/config/store';
import { claimPredictionRewards } from '../../redux/predictions';
import LoadingSpinner from '../icons/LoadingSpinner';
import { PredictionStates } from '../routes/MatchPrediction';
import Button from '../ui/Button';

export default function PredictionResolved() {
	const prediction = useActivePrediction();
	const { playerData } = usePlayerData();
	const dispatch = useAppDispatch();

	const [isWaitingForPrediction, setIsWaitingForPrediction] = useState(true);
	const [predictionState, setPredictionState] = useState<PredictionStates>(PredictionStates.WAITING);

	const { setBackground } = useBackground();
		
	useEffect(() => {
		setBackground('https://xrdatalakenab2022.blob.core.windows.net/react/uddq8e/assets/homepage_bg.jpg');
		setIsWaitingForPrediction(true);
		setPredictionState(PredictionStates.WAITING);
	}, []);

	const navigate = useNavigate();


	if (!prediction) {
		// Failsafe to prevent an infinite loading or a crash
		navigate(ROUTES.ROOT + ROUTES.MEDIA);
	}
	
	const isReady = Boolean(prediction && playerData && prediction?.data && prediction?.result);
	
	useEffect(() => {
		if (isReady) {
			setIsWaitingForPrediction(false);
			setPredictionState(PredictionStates.RESOLVED);
		}
	}, [isReady]);

	const question = prediction?.data?.questions[0];
	const answeredCode = playerData?.Challenges[prediction?.instanceId][question?.id];
	const correctCode = prediction?.result && prediction?.result[question?.id];
	const isCorrect = answeredCode === correctCode;

	const answeredLabel = question?.choices?.find((choice) => choice.code === answeredCode)?.label;
	const correctLabel = question?.choices?.find((choice) => choice.code === correctCode)?.label;

	function claim() {
		dispatch(claimPredictionRewards({ InstanceId: prediction?.instanceId }));
		navigate(ROUTES.ROOT + ROUTES.MEDIA);
	}

	let content = null;
	switch (predictionState) {
		case PredictionStates.RESOLVED:
			content = (
				<div className="prediction-resolved">
					<div className="title">Prediction resolved!</div>
					<div className="question">{question.question}</div>

					<div className="answers">
						<div className="subtitle">Your answer :</div>
						<div className={`answer ${isCorrect ? 'correct' : 'wrong'}`}>
							<div className="label">{answeredLabel}</div>
						</div>

						{!isCorrect && (
							<>
								<div className="subtitle">Correct answer :</div>
								<div className="answer correct">
									<div className="label">{correctLabel}</div>
								</div>
							</>
						)}
					</div>

					<Button className="big" onClick={claim} label="Claim your rewards" />
				</div>
			);
			break;
		case PredictionStates.WAITING:
		default:
			content = <LoadingSpinner className="spinner" />;
			break;
	}
	
	return (
		<CSSTransition appear timeout={1200} in>
			<div className="page match-prediction-page">
				<SwitchTransition>
					<CSSTransition appear key={predictionState} timeout={1200} in={isWaitingForPrediction}>
						{content}
					</CSSTransition>
				</SwitchTransition>
			</div>
		</CSSTransition>
	);
}