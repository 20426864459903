import { faBan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, memo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useOtherPlayerProfile } from '../../hooks/useOtherPlayer';
import useTeams from '../../hooks/useTeams';
import type { ChatMessage } from '../../redux/chat';
import LoadingSpinner from '../icons/LoadingSpinner';

type MessageProps = {
	message: ChatMessage;
	onHover?:({ message, y }:{ message: ChatMessage, y: number }) => void;
	onHoverOut?:() => void;
};

function Message({ message, onHover, onHoverOut }:MessageProps) {
	const { AvatarUrl, Statistics } = useOtherPlayerProfile(message.PlayFabId) || {};

	const ref = useRef<HTMLDivElement>(null);

	const teamId = (Statistics || []).find(s => s.Name === 'favorite_team')?.Value || 0;
	const { teams } = useTeams();
	const favoriteTeam = teams.find(t => t.data.TeamId === teamId);
	const color = favoriteTeam?.data.Color || '';

	const style = {
		'--color': color,
	} as CSSProperties;

	return (
		<CSSTransition in={true} appear nodeRef={ref} timeout={{ appear: 1200, enter: 1200, exit: 0 }}>
			<div className={`message ${message.Payload.IsModerated ? 'moderated' : ''}`} title={message.Payload.IsModerated ? 'This comment was moderated' : ''} ref={ref}>
				<div className="message-inner" style={style} onMouseEnter={() => onHover?.({ message, y: ref.current.getBoundingClientRect().top })} onMouseLeave={() => onHoverOut?.()}>
					{message.Payload.IsModerated && <span><FontAwesomeIcon icon={faBan} /> </span>}
					<div className="avatar">
						<div className="image-ctn">
							{AvatarUrl && <img src={AvatarUrl} alt="" />}
							{!AvatarUrl && <LoadingSpinner className="spinner" />}
						</div>
					</div>
					<span className="username">{message.DisplayName}: </span>
					<span className="message-content">{message.Payload.Message}</span>
				</div>
			</div>
		</CSSTransition>
	);
}

export default memo(Message);
