import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState, useAppDispatch } from '../redux/config/store';
import { getStoreLoadout } from '../redux/xr_store';
import useGlobalVariables from './useGlobalVariables';

export default function useXRStore() {
	const dispatch = useAppDispatch();
	const { StorePage, isLoaded: StorePageLoaded } = useGlobalVariables();
	
	const catalog = useSelector((state: IRootState) => state.catalog.items);
	const store = useSelector((state:IRootState) => state.xrStore.loadout);
	
	const [IsReady, SetIsReady] = useState(store.length !== 0);
	const [CurrentSlide, SetCurrentSlide] = useState(0);

	useEffect(() => {
		dispatch(getStoreLoadout()).then(() => { SetIsReady(true); });
	}, [store]);

	const StorePages = useMemo(() => {
		if (!StorePageLoaded || !IsReady || catalog.length === 0) {
			return [];
		}

		return StorePage.Slides.filter(s => StorePage.ActiveSlides.includes(s.Id)).map((item) => {
			return {
				...item,
				StoreCategories: item.StoreCategories.map((category) => {
					const storeLoadout = store.find(section => section.id === category.SectionId);

					return {
						...category,
						Products: storeLoadout ? storeLoadout.tiles.map((tile) => {
							return {
								tile,
								item: catalog.find(product => product.itemId === tile.content.ItemId),
							};
						}) : [],
					};
				}),
			};
		});
	}, [IsReady, store, StorePage]);

	StorePages.sort((a, b) => StorePage.ActiveSlides.indexOf(a.Id) - StorePage.ActiveSlides.indexOf(b.Id));

	const NextSlide = () => SetCurrentSlide(CurrentSlide + 1 > StorePages.length - 1 ? 0 : CurrentSlide + 1);
	const PreviousSlide = () => SetCurrentSlide(CurrentSlide - 1 < 0 ? StorePages.length - 1 : CurrentSlide - 1);

	return {
		StorePages,
		CurrentSection: StorePages[CurrentSlide],
		IsReady,
		CurrentSlide,
		SetCurrentSlide,
		NextSlide,
		PreviousSlide,
	};
}