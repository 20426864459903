import React, { memo } from 'react';
import { raw } from '../../utils/textUtils';
import Like from '../icons/Like';
import Share from '../icons/Share';
import Comment from '../icons/Comment';

type NewsCardProps = {
	Type: string,
	BackgroundImage: string,
	Title: string,
	Info: string,
	Comments: string,
	Likes: string,
	Link: string,
};

function NewsCard({
	Type,
	BackgroundImage,
	Title,
	Info,
	Comments,
	Likes,
	Link,
}:NewsCardProps) {
	return (
		<a href={Link} target="_blank" className={`news-card ${Type}`}>
			<div className="bg">
				<img src={BackgroundImage} alt="" />
			</div>
			<div className="overlay">
				<div className="title" {...raw(Title)} />
				<div className="news-info" {...raw(Info)} />
				<div className="news-footer">
					<div className="share"><Share /></div>
					<div className="comments"><span {...raw(Comments)} /> <Comment /></div>
					<div className="likes"><span {...raw(Likes)} /> <Like /></div>
				</div>
			</div>
		</a>
	);
}

export default memo(NewsCard);