import React, { memo, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import useEnv from '../../hooks/useEnv';
import useGlobalVariables from '../../hooks/useGlobalVariables';
import { raw } from '../../utils/textUtils';
import Arrow from '../icons/Arrow';
import Button from '../ui/Button';
import LinedTitle, { LinedTitleTypes } from '../ui/LinedTitle';
import Loading from '../ui/Loading';
import MatchCard from '../ui/MatchCard';
import useBackground from '../../hooks/useBackground';

function Media() {
	const { MediaPage, isLoaded } = useGlobalVariables();
	const { Lang } = useEnv();
	const page = useRef<HTMLDivElement>(null);

	const {
		PageTitle,
		PageUpperTitle,
		Featured,
		FriendsTitle,
		Friends,
		LatestResultsTitle,
		LatestResults,
		Schedules,
		BackgroundImage,
	} = MediaPage || {};

	const { setBackground } = useBackground();
	useEffect(() => {
		setBackground(BackgroundImage);
	});
	
	if (!isLoaded) return (
		<Loading isLoading={isLoaded} />
	);

	return (
		<CSSTransition nodeRef={page} key="media" timeout={1200} in={true} appear>
			<div className="page media" ref={page}>
				<div className="page-content">
					<div className="page-title">
						<div className="upper-title" {...raw(PageUpperTitle)} />
						<div className="title" {...raw(PageTitle)} />
					</div>

					<div className="featured">
						<div className="bg">
							<img width="590" height="320" src={Featured.BackgroundImage} alt="" />
						</div>
						<div className="overlay">
							<div className="title" {...raw(Featured.Title)} />
							<div className="subtitle" {...raw(Featured.Subtitle)} />
						</div>
						<Button to={Featured.ButtonLink} label={Featured.ButtonLabel} hasArrow />
					</div>

					<div className="schedules">
						{Schedules.map((schedule) => {
							return (
								<div className="schedule" key={schedule.Title}>
									<LinedTitle text={schedule.Title} caps />
									<div className="matches">
										{schedule.Matches.map((match) => {
											return (
												<MatchCard {...match} key={JSON.stringify(match)} />
											);
										})}
									</div>
								</div>
							);
						})}
					</div>
				</div>

				<div className="sidebar">
					<LinedTitle text={FriendsTitle} size={LinedTitleTypes.LARGE} />

					<div className="friends">
						{Friends.map(f => {
							return (
								<div className={`friend ${f.IsOnline ? 'online' : 'offline'}`} key={f.DisplayName}>
									<div className="wrapper">
										<div className="avatar">
											<img src={f.AvatarUrl} alt="" />
										</div>
										<div className="infos">
											<div className="name" {...raw(f.DisplayName)} />
											<div className="status" {...raw(f.Status)} />
										</div>
										<div className="last-online">
											<div className="time">{Intl.DateTimeFormat(Lang).format(new Date())}</div>
										</div>
									</div>
									{f.WatchParty && (
										<div className="watch-party">
											<Button to={f.WatchParty} label="Join Watch Party" hasArrow />
										</div>
									)}
								</div>
							);
						})}
					</div>

					<div className="latest-results">
						<LinedTitle text={LatestResultsTitle} size={LinedTitleTypes.LARGE} />
						<div className="results">
							{LatestResults.Results.map(r => {
								return (
									<div className="result match-card" key={JSON.stringify(r)}>
										<div className="wrapper">
											<div className="top">
												<div className="bg">
													<img width="230" height="128" src={r.BackgroundImage} alt="" />
												</div>
												<div className="text">
													<div className="team" {...raw(r.Team1Name)} />
													<div className="score" {...raw(r.Team1Score)} />
												</div>
											</div>
											<div className="bottom">
												<div className="team"><small>vs</small> <span {...raw(r.Team2Name)} /></div>
												<div className="score" {...raw(r.Team2Score)} />
											</div>
										</div>
										<div className="btns">
											{r.Buttons.map(b => {
												return (
													<NavLink className="result-button" key={b.Label} to={b.Link}><div className="arrow"><Arrow /></div>{b.Label}</NavLink>
												);
											})}
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}

export default memo(Media);