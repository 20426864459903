
import React, { memo, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { IPlayerStatistics } from '../../redux/statistics';
import StatBar from '../ui/StatBar';
import useRewardTrack from './hooks/useRewardTrack';
import RewardTrackItem from './RewardTrackItem';

type RewardTrackProps = {
	trackId: string;
	thresholds?: number[];
	showTracker?: boolean;
	statOverride?: keyof IPlayerStatistics;
	scrolled?:boolean;
};

function RewardTrack({ trackId, thresholds = null, showTracker = true, statOverride = null, scrolled = false }:RewardTrackProps) {
	const {
		track,
		iterator,
		stat,
	} = useRewardTrack(trackId, thresholds, statOverride);

	const iteratorThresholds = thresholds || iterator;

	const classes = ['reward-track', track.type];
	if (scrolled) classes.push('scrolled');
	if (track.isActive) classes.push('is-active');
	if (showTracker) classes.push('has-tracker');

	const ref = useRef<HTMLTableCellElement>();

	useEffect(() => {
		const scrollingElement = ref?.current?.closest?.('.reward-track-table-container');

		function onScroll() {
			if (scrollingElement.scrollLeft > 0) {
				ref.current.classList.add('scrolled');
			} else {
				ref.current.classList.remove('scrolled');
			}
		}

		scrollingElement?.addEventListener?.('scroll', onScroll);
		
		return () => {
			scrollingElement?.removeEventListener?.('scroll', onScroll);
		};
	}, [ref]);

	return track && (
		<CSSTransition in={true} timeout={1200} appear>
			<tr className={classes.join(' ')}>
				<td className="reward-track-name" ref={ref}>
					{track.name}
					{track.isActive && <div className="active">Active</div>}
				</td>
				{iterator.map((threshold, i) => {
					const tier = track.tiers.find(t => t.threshold === threshold);
					if (!tier) {
						return <td key={i} />;
					}

					const minIndex = iteratorThresholds.findIndex(t => t >= tier.threshold) - 1;
					const min = iteratorThresholds[minIndex] || 0;
					const max = iteratorThresholds[minIndex + 1] || iteratorThresholds[iteratorThresholds.length - 1];

					const cl = ['reward-tier'];
					if (i === 0) cl.push('first');
					if (tier.isComplete) cl.push('complete');

					return (<td key={i} className={cl.join(' ')}>
						<div className="reward-tier-items">
							{tier.rewards.map((item) => item && (
								<RewardTrackItem
									item={item}
									isComplete={tier.isComplete}
									isClaimed={false}
									key={item.id}
								/>
							))}
							{tier.rewards.length === 0 && <div className="reward-tier-item"></div>}
						</div>
						{showTracker && <StatBar value={stat} min={min} max={max} />}
					</td>);
				})}
			</tr>
		</CSSTransition>
	);
}

export default memo(RewardTrack);