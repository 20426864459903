import React, { memo, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import useGlobalVariables from '../../hooks/useGlobalVariables';
import useXP from '../../hooks/useXP';
import GlobalSidebar from '../GlobalSidebar';
import useRewardTracks from '../reward-track/hooks/useRewardTracks';
import RewardTrack from '../reward-track/RewardTrack';
import Loading from '../ui/Loading';
import XpBar from '../ui/XPBar';
import useBackground from '../../hooks/useBackground';

function RewardTracks() {
	const { RewardTrackPage, isLoaded } = useGlobalVariables();
	const page = useRef<HTMLDivElement>(null);
	const content = useRef<HTMLDivElement>(null);

	const {
		xp,
		level,
		nextLevelXp,
		currentLevelXp,
	} = useXP();

	const {
		BackgroundImage,
	} = RewardTrackPage || {};

	const { setBackground } = useBackground();
	useEffect(() => {
		setBackground(BackgroundImage);
	});

	const tracks = useRewardTracks().filter(t => t.type.includes('journey'));

	tracks.sort((a, b) => Number(a.type.includes('premium')) - Number(b.type.includes('premium')));
	
	if (!isLoaded) return (
		<Loading isLoading={isLoaded} />
	);

	return (
		<CSSTransition nodeRef={page} key="home" timeout={1200} in={true} appear>
			<div className="page reward-track-page home" ref={page}>
				<div className="page-content">
					<CSSTransition nodeRef={content} key="home" timeout={1200} in={tracks.length > 0} appear mountOnEnter unmountOnExit>
						<div className="content" ref={content}>
							<h2>Your NAB Journey</h2>
							<h1>REWARD<br/>TRACK</h1>

							<XpBar xp={xp} level={level} currentLevelXp={currentLevelXp} nextLevelXp={nextLevelXp} />

							<div className="reward-track-table-container">
								<table className="reward-tracks">
									<tbody>
										{tracks.map((track, i) => <RewardTrack key={track.id} trackId={track.id} showTracker={i === 0} />)}
									</tbody>
								</table>
							</div>
						</div>
					</CSSTransition>
				</div>
				<GlobalSidebar />
			</div>
		</CSSTransition>
	);
}

export default memo(RewardTracks);
