import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ITEM_CLASSES } from '../Constants';
import { IRootState, useAppDispatch } from '../redux/config/store';
import { writePlayerEvent } from '../redux/playfab';
import { updateLocalStatistic } from '../redux/statistics';

export default function useTeams() {
	const dispatch = useAppDispatch();
	const favoriteTeam = useSelector((state:IRootState) => state.statistics.favorite_team);
	const teams = useSelector((state:IRootState) => state.catalog.items.filter(item => item.playfab.ItemClass === ITEM_CLASSES.TEAM));

	function setFavoriteTeam(teamId:number) {
		if (favoriteTeam === teamId) return;

		dispatch(writePlayerEvent({
			name: 'set_favorite_team',
			body: {
				team_id: teamId,
			},
		}));

		dispatch(updateLocalStatistic({
			name: 'favorite_team',
			value: teamId,
		}));
	}

	return {
		teams: useMemo(() => {
			const arr = teams.map((team) => {
				const t = JSON.parse(JSON.stringify(team)) as IXRInventoryItemParsedData;
				t.data.IsFavorite = team.data.TeamId === favoriteTeam;
				return t;
			});

			arr.sort((a, b) => (a.data.TeamId  as number) - (b.data.TeamId as number));

			return arr;
		}, [teams, favoriteTeam]),
		hasFavoriteTeam: favoriteTeam !== 0,
		favoriteTeam: favoriteTeam !== 0 && teams.find(team => team.data.TeamId === favoriteTeam),
		setFavoriteTeam,
	};
}