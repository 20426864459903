import React, { memo, useLayoutEffect, useRef, CSSProperties, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import videojs from 'video.js';
import 'videojs-overlay';
import '@videojs/http-streaming';
import EaseLive from '@ease-live/ease-live-bridge-web';
import { useNavigate } from 'react-router-dom';

import { PLAYFAB_CONFIG, /*PREDICTION_MATCH_ID,/** */ ROUTES, XR_CONFIG } from '../../Constants';
import useGlobalVariables from '../../hooks/useGlobalVariables';
import useMatchAnalytics from '../../hooks/useMatchAnalytics';
// import ChatBox from '../chat/ChatBox';
import { getXrApi } from '../../api/apiBridge';
import usePlayer from '../../hooks/usePlayer';
import useBackground from '../../hooks/useBackground';
// import { writePlayerEvent } from '../../redux/playfab';
// import { useAppDispatch } from '../../redux/config/store';

function Match() {
	const navigate = useNavigate();
	// const dispatch = useAppDispatch();
	const videoContainer = useRef<HTMLVideoElement>(null);
	const page = useRef<HTMLDivElement>(null);

	const {
		playerId,
	} = usePlayer();

	const {
		MatchPage,
		// AppSettings,
	} = useGlobalVariables();

	const {
		BackgroundImage,
	} = MatchPage || {};

	const style = {
		'--background-image': `url(${BackgroundImage})`,
	} as CSSProperties;

	const {
		Hls,
		AccountId,
		ProgramId,
		TimeOffset,
	} = MatchPage || {};

	useMatchAnalytics();

	const { setBackground } = useBackground();
	useEffect(() => {
		setBackground('');
	});

	/*useEffect(() => {
		dispatch(writePlayerEvent({ name: 'prediction_game_ended', body: { winning_team: 'Heat', match_id: PREDICTION_MATCH_ID } }));
	}, []);/** */

	useLayoutEffect(() => {
		if (!playerId || !Hls) return () => {};
		
		const player = videojs(videoContainer.current);

		const easeLiveContainer = document.createElement('div');
		easeLiveContainer.classList.add('easelive-container');

		player.overlay({
			content: easeLiveContainer,
		});

		player.src({
			src: Hls,
			html5: {
				vhs: {
					useCueTags: true,
				},
			},
		});
		
		const timeOffset = new Date(TimeOffset).getTime();

		const easeLive = new EaseLive({
			accountId: AccountId,
			programId: ProgramId,
			viewContainer: easeLiveContainer,
			params: {
				sessionToken: getXrApi().GetSessionTicket(),
				playFabId: playerId,
				appId: PLAYFAB_CONFIG.appId,
				xrAppId: XR_CONFIG.appId,
				hostName: window.location.hostname,
				apiEndpoint: XR_CONFIG.apiUrl,
			},
			playerPlugin: (bridge) => {
				player.ready(() => {
					easeLive.emit('player.ready', {});
				});

				player.on(['pause', 'waiting'], () => {
					bridge.emit('player.state', { state: 'paused' });
				});
				player.on(['playing', 'seeked'], () => {
					bridge.emit('player.state', { state: 'playing' });
				});
				player.on('stalled', () => {
					bridge.emit('player.state', { state: 'buffering' });
				});
				player.on('seeking', () => {
					bridge.emit('player.state', { state: 'seeking' });
				});

				player.on('timeupdate', () => {
					const playerPositionMs = Math.floor(player.currentTime() * 1000);
					const timecode = timeOffset + playerPositionMs;
					easeLive.emit('player.time', { timecode });
				});

				player.textTracks().addEventListener('addtrack', (addTrackEvent) => {
					const track = addTrackEvent.track;

					track.addEventListener('cuechange', () => {
						if (track.activeCues.length <= 0) {
							return;
						}

						const playerPositionMs = Math.floor(player.currentTime() * 1000);
						const timecode = timeOffset + playerPositionMs;

						easeLive.emit('player.time', { timecode });
					}, false);
				}, false);
			},
		});

		// event from EaseLive to seek to the position of an absolute time
		easeLive.on('player.time', ({ timecode }, sender) => {
			if (!sender) return;

			// calculate the timeline position for the absolute time

			const playerPositionMs = Math.floor(player.currentTime() * 1000);
			const currentTimecode = timeOffset + playerPositionMs;

			const diff = timecode - currentTimecode;

			const position = (playerPositionMs + diff) / 1000;
			player.currentTime(position);
		});

		easeLive.init();

		return () => {
			easeLive.destroy();
			if (easeLiveContainer) {
				easeLiveContainer.remove();
			}
		};
	}, [playerId, Hls]);

	function onClose() {
		navigate(ROUTES.ROOT + ROUTES.MEDIA);
	}

	return playerId && Hls && (
		<CSSTransition nodeRef={page} key="match" timeout={1200} in={true} appear>
			<div className="page match" style={style} ref={page}>
				<div className="bg"></div>
				{<div onClick={onClose} className="close-match">
					<span className="text">Close <strong>Match</strong></span><span className="times">&times;</span>
				</div>}
				<video
					data-setup='{ "inactivityTimeout": 0 }'
					autoPlay
					muted
					controls
					playsInline
					className="video-js vjs-theme-city"
					ref={videoContainer}
				/>
				{/* <ChatBox roomId={`match-${AppSettings.Sport}`} /> */}
			</div>
		</CSSTransition>
	);
}

export default memo(Match);