import { useCallback, useEffect } from 'react';
import { XRAdActivityTypes } from '../api/enums';
import { getSingleAdPlacement, removeAd, reportAdActivity, skipAdPlacement } from '../redux/ads';
import { useAppDispatch, useAppSelector } from '../redux/config/store';

export enum AdSpaceTypes {
	BigBox = 'big-box',
	Leaderboard  = 'leaderboard',
	SkyScraper = 'sky-scraper',
}

const AdSpaceSizes = {
	[AdSpaceTypes.BigBox]: '300x250',
	[AdSpaceTypes.Leaderboard] : '728x90',
	[AdSpaceTypes.SkyScraper]: '160x600',
};

export default function useAdPlacement(id:string, autoFetch = true) {
	const dispatch = useAppDispatch();

	const placement = useAppSelector(state => state.ads[id]);

	const fetch = useCallback(() => {
		dispatch(getSingleAdPlacement(id));
	}, [id]);

	useEffect(() => {
		if (id && autoFetch) {
			fetch();
		}
	}, [id, autoFetch]);

	const type = AdSpaceTypes.BigBox;

	const prices = placement?.virtualCurrencyPrices;

	const skipped = placement?.skippedUntil && new Date(placement.skippedUntil).valueOf() > new Date().valueOf();

	return placement ? {
		type,
		isActive: Boolean(placement.itemId) && !skipped,
		img: placement.data.Image,
		size: placement && AdSpaceSizes[placement.data.Format],
		iframe: placement.data.IFrame && decodeURI(placement.data.IFrame),
		video: placement.data.Video,
		prices,
		rewards: placement.rewards,
		duration: placement.data.Duration,

		removeAd: () => dispatch(removeAd({ placementId: id })),
		refresh: () => fetch(),
		skipAd: () => dispatch(skipAdPlacement({ PlacementId: id, CurrencyCode: 'CO' })),
		reportAdActivity: (activity:XRAdActivityTypes) => dispatch(reportAdActivity({ PlacementId: id, Activity: activity })),
	} : {};
}