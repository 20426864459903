import React, { CSSProperties, memo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useOtherPlayerProfile } from '../../hooks/useOtherPlayer';
import useTeams from '../../hooks/useTeams';
import { ChatMessage } from '../../redux/chat';
import LoadingSpinner from '../icons/LoadingSpinner';

type ChatTooltipProps = {
	message: ChatMessage;
	y: number;
};

function ChatTooltip({ message, y = 0 }:ChatTooltipProps) {
	const ref = useRef<HTMLDivElement>(null);
	const { AvatarUrl, Statistics } = useOtherPlayerProfile(message?.PlayFabId) || {};

	const teamId = (Statistics || []).find(s => s.Name === 'favorite_team')?.Value || 0;
	const level = (Statistics || []).find(s => s.Name === 'level')?.Value || 1;
	const { teams } = useTeams();
	const favoriteTeam = teams.find(t => t.data.TeamId === teamId);
	const color = favoriteTeam?.data.Color || '';

	const style = {
		'--color': color,
		'--y': y + 'px',
	} as CSSProperties;

	return (
		<CSSTransition nodeRef={ref} appear timeout={{ appear: 1200, enter: 1200, exit: 0 }} in={Boolean(message) && Boolean(AvatarUrl)} mountOnEnter unmountOnExit>
			<div className="chat-tooltip" style={style} ref={ref}>
				<div className="info-card-inner">
					<div className="avatar">
						<div className="level-badge">lvl {level}</div>
						<div className="image-ctn">
							{AvatarUrl && <img src={AvatarUrl} width="200" height="200" alt="" />}
							{!AvatarUrl && <LoadingSpinner className="spinner" />}
						</div>
					</div>
					<div className="info">
						<div className="username">{message?.DisplayName}</div>
						<div className="favorite-team">
							{favoriteTeam && <><img width="200" height="200" src={favoriteTeam.data.Logo as string} alt="" /> Fan</>}
						</div>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}

export default memo(ChatTooltip);
