import React, { useCallback } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../redux/config/store';
import { raw } from '../../utils/textUtils';

type MatchCardProps = {
	className?: string,
	BackgroundImage: string,
	Team1Name: string,
	Team1Score?: string,
	Team2Name: string,
	Team2Score?: string,
	MatchDate?: string,
};

function MatchCard({
	className = '',
	BackgroundImage,
	Team1Name,
	Team1Score = '',
	Team2Name,
	Team2Score = '',
	MatchDate = '',
}:MatchCardProps) {
	const lang = useSelector((state:IRootState) => state.env.Lang);

	const getDate = useCallback(() => {
		const d = new Date(MatchDate);

		return (
			<div className="match-date">
				<div className="day">{Intl.DateTimeFormat(lang, { weekday: 'short' }).format(d)}</div>
				<div className="date">{Intl.DateTimeFormat(lang, { day: '2-digit' }).format(d)}</div>
			</div>
		);
	}, [lang]);

	return (
		<div className={`${className} match-card`}>
			{MatchDate && getDate()}
			<div className="wrapper">
				<div className="top">
					<div className="bg">
						<img width="230" height="128" src={BackgroundImage} alt="" />
					</div>
					<div className="text">
						<div className="team" {...raw(Team1Name)} />
						{Team1Score && <div className="score" {...raw(Team1Score)} />}
					</div>
				</div>
				<div className="bottom">
					<div className="team"><small>vs</small> <span {...raw(Team2Name)} /></div>
					{Team2Score && <div className="score" {...raw(Team2Score)} />}
				</div>
			</div>
		</div>
	);
}

export default memo(MatchCard);