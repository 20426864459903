import React, { memo, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import useGlobalVariables from '../../hooks/useGlobalVariables';
import { raw } from '../../utils/textUtils';
import GlobalSidebar from '../GlobalSidebar';
import Button from '../ui/Button';
import Loading from '../ui/Loading';
import useBackground from '../../hooks/useBackground';

function Home() {

	const { HomePage, isLoaded } = useGlobalVariables();
	const page = useRef<HTMLDivElement>(null);

	const {
		BackgroundImage,
		UpcomingMatch,
	} = HomePage || {};

	const { setBackground } = useBackground();
	useEffect(() => {
		setBackground(BackgroundImage);
	});
	
	if (!isLoaded) return (
		<Loading isLoading={isLoaded} />
	);

	return (
		<CSSTransition nodeRef={page} key="home" timeout={1200} in={true} appear>
			<div className="page home" ref={page}>
				<div className="page-content">
					<div className="page-title">
						<div className="upcoming-match">
							<div className="date" {...raw(UpcomingMatch.Date)} />
							<div className="team-name" {...raw(UpcomingMatch.Team1)} />
							<div className="mid">
								<div className="vs" {...raw(UpcomingMatch.Vs)} />
								<Button to={UpcomingMatch.ButtonLink} label={UpcomingMatch.ButtonLabel} hasArrow />
							</div>
							<div className="team-name" {...raw(UpcomingMatch.Team2)} />
						</div>
					</div>
				</div>

				<GlobalSidebar />
			</div>
		</CSSTransition>
	);
}

export default memo(Home);
