import React from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faFilms, faShirt, faBarsProgress } from '@fortawesome/pro-regular-svg-icons';
import { ROUTES } from '../Constants';
import useAppData from '../hooks/useAppData';
import Avatar from './ui/Avatar';
import LevelBadge from './ui/LevelBadge';
import XpFloaters from './ui/XpFloaters';

function Nav() {
	const isLogin = useMatch(ROUTES.ROOT + ROUTES.LOGIN);
	const isHighlightMatch = useMatch(ROUTES.ROOT + ROUTES.HIGHLIGHT_MATCH);
	const isMatch = useMatch(ROUTES.ROOT + ROUTES.MATCH);
	const isMachPredictionResolve = useMatch(ROUTES.ROOT + ROUTES.MATCH_PREDICTION_RESOLVE);
	const isMatchPrediction = useMatch(ROUTES.ROOT + ROUTES.MATCH_PREDICTION);

	const { Logo } = useAppData();

	const isHidden = isHighlightMatch || isMatch || isLogin || isMatchPrediction || isMachPredictionResolve;
	
	return (
		<nav className={`main-nav ${isHidden ? 'hidden' : ''}`}>
			<ul className="menu">
				<li className="menu-item"><NavLink to={ROUTES.ROOT}><FontAwesomeIcon icon={faHouse} /></NavLink></li>
				<li className="menu-item"><NavLink to={ROUTES.MEDIA}><FontAwesomeIcon icon={faFilms} /></NavLink></li>
				<li className="menu-item"><NavLink to={ROUTES.STORE}><FontAwesomeIcon icon={faShirt} /></NavLink></li>
				<li className="menu-item"><NavLink to={ROUTES.REWARD_TRACKS}><FontAwesomeIcon icon={faBarsProgress} /></NavLink></li>
			</ul>

			<NavLink to={ROUTES.ROOT} className="home-button">
				<img src={Logo} alt="" />
			</NavLink>

			<NavLink to={ROUTES.PROFILE} className="profile-button">
				<XpFloaters />
				<LevelBadge />
				<Avatar />
			</NavLink>

			<div className="bg">
				<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 99.67 810.06"><path d="M99.667 0v447.19C94.383 497.474 74.44 548.941 74.44 603.912a99.648 99.648 0 0 0 .819 12.456c5.464 44.332 20.26 89.176 24.408 131.36v62.333H0V0h99.667Z" fill="rgba(150,149,153,0.22)"/></svg>
			</div>
		</nav>
	);
}

export default Nav;